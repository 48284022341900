import './getrequest.css';
import LeftArrow from './../../../Assets/Dashboard/LeftArrow.svg';


const GetPaidRequest =()=>{
    return(
<div className="paidRequests">
    <div className='Amount'>
      <img src={LeftArrow} alt='Arrow'/>
        <p className='amountTypo'>Amount</p>
    </div>
    <div className='firstTable'>
  

    </div>


</div>
    );
};
export default GetPaidRequest;