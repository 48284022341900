import React from "react";
import { useState, useEffect } from 'react';
import './history.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function createData(name, amount, method, accountName, accNo, City, date) {
    return { name, amount, method, accountName, accNo, City, date  };
  }
  const historyrows = [
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
    
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
   
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
    createData('AKEMIWRLD', '£250,000', 'Bank Account', 'John Williams', '126283399384039', 'USA,Texas', '11/10/24',),
   
    
  ];
const GetHistory = () => {
    return(
        <div className="History">
 <TableContainer sx={{height:"721px"}} component={Paper}>
        <Table sx={{   background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06))',
 minWidth: 650}} aria-label="simple table">
          <TableHead> 
            <TableRow
        sx={{
           backgroundColor: 'rgba(41, 41, 41, 1)', 
        }}
      >
              <TableCell sx={{ borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'17.5px', paddingBottom:'17.5px', paddingLeft:'20px' , width:'158px'}}>Name</TableCell>
              <TableCell sx={{borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'17.5px', paddingBottom:'17.5px', paddingLeft:'20px' , width:'141px'}} align="left">Amount</TableCell>
              <TableCell  sx={{borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'17.5px', paddingBottom:'17.5px', paddingLeft:'20px' , width:'177px'}} align="left">Withdrawal Method</TableCell>
              <TableCell  sx={{borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'17.5px', paddingBottom:'17.5px', paddingLeft:'20px' , width:'160px'}} align="left"> Account Name</TableCell>
              <TableCell  sx={{borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'17.5px', paddingBottom:'17.5px', paddingLeft:'20px' ,paddingRight:'0px', width:'211px'}} align="left">Acc No/ Wallet Address</TableCell>
              <TableCell  sx={{borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'17.5px', paddingBottom:'17.5px', paddingLeft:'12.5px' , width:'130px'}} align="left">City/Chain</TableCell>
              <TableCell  sx={{borderBottom:'1px solid rgba(41, 41, 41, 1)',fontFamily:"Nexa", fontSize:'14px', fontWeight:'800', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px', paddingTop:'13px', paddingBottom:'13px', paddingLeft:'20px' , width:'100px'}}align="left">Date</TableCell>
              </TableRow>
            <TableRow>
    <TableCell colSpan={8} sx={{ height: '16px', backgroundColor: 'black', borderBottom: 'none' }} />
  </TableRow>
          </TableHead>
          <TableBody>
         
            {historyrows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ backgroundColor: 'rgba(41, 41, 41, 1)', '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'25px', paddingLeft:'20px',paddingBottom:'25px', width:'188px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'700', color:'rgba(255, 255, 255, 1)', lineHeight:'20.3px'}} align="Left"> {row.name}  </TableCell>
                <TableCell  sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'25px', paddingLeft:'20px',paddingBottom:'25px', width:'188px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'400', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px'}} align="left">{row.amount}</TableCell>
                <TableCell sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'20px', paddingLeft:'20px',paddingBottom:'20px', width:'152px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'400', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px'}} align="left">{row.method}</TableCell>
                <TableCell sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'20px', paddingLeft:'20px',paddingBottom:'20px', width:'175px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'400', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px'}}  align="left">{row.accountName}</TableCell>
                <TableCell sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'20px', paddingLeft:'20px',paddingBottom:'20px', width:'175px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'400', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px'}}  align="left">{row.accNo}</TableCell>
                <TableCell sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'20px', paddingLeft:'20px',paddingBottom:'20px', width:'141px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'400', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px'}}  align="left">{row.City}</TableCell>
                <TableCell sx={{height:'45px',borderBottom:'1px solid rgba(41, 41, 41, 1)',paddingTop:'25px', paddingLeft:'20px',paddingBottom:'25px', width:'188px',fontFamily:"Nexa", fontSize:'14px', fontWeight:'400', color:'rgba(143, 143, 143, 1)', lineHeight:'20.3px'}} >
                {row.date}  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <button className='Export'>
      Export
      </button>
        </div>
    );
};
export default GetHistory;