import React from "react";
import { useState,useEffect } from "react";
import './FollowingTicket.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";
const FollowingTicket = (followingData) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    setLoading(true);
    
    if (followingData?.followingData?.getOrganizationProfileData?.data?.data) {
      setProfileData(followingData.followingData.getOrganizationProfileData.data.data);
    }
  
    setLoading(false); 
  }, [followingData]); 
  

  console.log("profileData state:", profileData);

  const convertToCSV = (profileData) => {
    const header = ['Organiser Name', 'Email', 'Events', 'Followers', 'Sales'];
    const row = [
      profileData?.profile?.name || "-------",
      profileData?.profile?.email || "-------",
      profileData?.profile?.totalEvents || "-------",
      profileData?.totalFollowers || "-------",
      profileData?.profile?.totalSales || "-------",
    ];
    const csvContent = [
      header.join(','), 
      row.join(','),   
    ].join('\n');

    return csvContent;
  };
  const downloadCSV = () => {
    const csvData = convertToCSV(profileData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'profile_data.csv';
    link.click();

    URL.revokeObjectURL(url);
  };
  return (
    <>
      {loading && <ScreenLoader />}
    
    <div className="personal-info-detail-container-----FOLLOWING">
    
      <div className="given-to-the-width-to-all">
        <Box>
          <Box sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}></Box>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Organiser Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Events
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Followers
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Sales
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Spacer row for gap */}
              <TableRow sx={{ height: "16px", backgroundColor: "#030303", }}>
                <TableCell colSpan={6}></TableCell>
              </TableRow>

              <TableBody>
               
                  <TableRow  className="main-even-name-text-style-for">
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: '275px' }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                   {profileData?.profile?.name || "-------"}

                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: '275px' }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                     {profileData?.profile?.email|| "-------"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: '275px' }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                     {profileData?.profile?.totalEvents|| "-------"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: '275px' }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                     {profileData?.totalFollowers|| "-------"}
                    </TableCell>
                    {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="main-even-name-text-style-for-padding-margin-type-false-true"
                  >
                    {row.attended ? (
                      <Check sx={{ color: "rgba(0, 208, 89, 1)" }} />
                    ) : (
                      <Close sx={{ color: "rgba(255, 23, 23, 1)" }} />
                    )}
                  </TableCell> */}
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: '275px' }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                    {profileData?.profile?.totalSales|| "-------"}
                    </TableCell>
                  </TableRow>
               
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>


      <div className="export-button">
        <button onClick={downloadCSV}>Export</button>
      </div>
    </div>
    </>
  );
};

export default FollowingTicket;
