import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getScannerDetail } from "../middleware/getScannerDetail";

const initialState = {
  loading: false,
  error: "",
  scannerData: [],
};
const  getScannerDetailSlice = createSlice({
  name: " getScannerDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScannerDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getScannerDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.scannerData = action.payload;
    });
    builder.addCase(getScannerDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getScannerDetailSlice.reducer;