import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./PersonalInfoDetail.css"; // Import the CSS file for custom styling
import EventTicket from "../EventTicket/EventTicket";
import MrtRewards from "../MRTRewards/MrtRewards";
import CollectiblesOwned from "../CollectiblesOwned/CollectiblesOwned";
import PhotoRoll from "../PhotoRoll/PhotoRoll";

import FollowingTicket from "../FollowingTickets/FollowingTicket";
import Organiser_Detail_Liked_Events from "../../Components/Organiser_Detail_Liked_Events/Organiser_Detail_Liked_Events";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserWithId } from "../../services/redux/middleware/getAllUserById";
import { getClaimsByUserId } from "../../services/redux/middleware/getClaimByUser";
import { getEventsDetails } from "../../services/redux/middleware/getEventsDetail";
import { getUserCollectibles } from "../../services/redux/middleware/getUserCollectibles";
import { getOrganizationProfile } from "../../services/redux/middleware/getOrganizationprofile";
import { getChatPictures } from "../../services/redux/middleware/getChatPictures";
import { getLikedEvents } from "../../services/redux/middleware/getLikedEvents";
import ScreenLoader from "../../Components/loader/screenLoader";
const PersonalInfoDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [act, setact] = useState("A");
  const [personalinfo, setpersonalinfo] = useState();
  const [rewardinfo, setrewardInfo] = useState();
  const [eventTicket, setEventTicket] = useState();
  const [userCollectibles, setUserCollectibles] = useState();
  const [likedEventData, setlikedEventData] = useState();
  const [organizerData, setOrganizerData] = useState();
  const [photoRoll, setphotoRoll] = useState();
  const handlebtnclick = (active) => {
    setact(active);
  };
  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  }

  const isActive = (path) => location.pathname === path;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const userData1 = useSelector((state) => state?.getAllUserWithId);
  const rewardData = useSelector((state) => state?.getClaimByUser);
  const eventTicketData = useSelector((state) => state?.getEventsDetail);
  const userCollectiblesdata = useSelector((state) => state?.getUserCollectibles);
  const organizationprofileData = useSelector((state) => state?.getOrganizationProfile);
  const photoRollData = useSelector((state) => state?.getChatPictures);
  const likedEvents = useSelector((state) => state?.getLikedEvents);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(getAllUserWithId({ id: id }));
        await dispatch(getClaimsByUserId({ userId: id }));
        await dispatch(getEventsDetails({ userId: id }));
        await dispatch(getUserCollectibles({ userId: id }));
        await dispatch(getOrganizationProfile({ userId: id }));
        await dispatch(getChatPictures({ userId: id }));
        await dispatch(getLikedEvents({ userId: id }));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
  
    fetchData();
  }, [id, dispatch]);

  useEffect(() => {
    if (userData1?.userData?.data?.data) {
      setpersonalinfo(userData1.userData.data.data);
    }

    if (rewardData) {
      setrewardInfo(rewardData);
    }

    if (eventTicketData) {
      setEventTicket(eventTicketData);
    }

    if (userCollectiblesdata) {
      setUserCollectibles(userCollectiblesdata);
    }

    if (photoRollData) {
      setphotoRoll(photoRollData);
    }

    if (likedEvents) {
      setlikedEventData(likedEvents);
    }

    if (organizationprofileData) {
      setOrganizerData(organizationprofileData);
    }


  }, [userData1, rewardData, eventTicketData, userCollectiblesdata, photoRollData, likedEvents]);
  const handleExportCSV = () => {
    if (!personalinfo) {
      alert("No data available to export");
      return;
    }
    const headers = [
      "Field",
      "Value"
    ];

    const rows = [
      ["Profile Picture", personalinfo?.profilePicture || "No Image"],
      ["Name", personalinfo?.fullname || "No Name"],
      ["Email", personalinfo?.email === "" ? "Not Linked" : personalinfo?.email],
      ["Facebook", personalinfo?.fbUrl === null ? "No Link" : personalinfo?.fbUrl],
      ["Instagram", personalinfo?.instaUrl === null ? "No Link" : personalinfo?.instaUrl],
      ["Telegram", personalinfo?.telegramUrl === null ? "No Link" : personalinfo?.telegramUrl],
      ["LinkedIn", personalinfo?.linkedinUrl === null ? "No Link" : personalinfo?.linkedinUrl],
      ["YouTube", personalinfo?.youtubeUrl === null ? "No Link" : personalinfo?.youtubeUrl],
      ["TikTok", personalinfo?.tiktokUrl === null ? "No Link" : personalinfo?.tiktokUrl],
      ["Twitter", personalinfo?.twitterUrl === null ? "No Link" : personalinfo?.twitterUrl],
    ];

    let csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "profile_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
<> 
{loading && <ScreenLoader />}
    <div className="personal-info-detail-container__E_e"
   
    >
      <div className="Simple-User-Details-heading-main">
        <div className='Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image'>
          <img src='/Payout_Images/graphlogo.svg' alt='' onClick={goBack} />
          <h2>Simple User Details</h2>
        </div>
        <div className="top-buttons">
          <button
            style={{
              background:
                act === "A"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "A" ? "#00A849" : "#FFFFFF",
              border:
                act === "A"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/personal-information") ? "active" : ""}
            onClick={() => handlebtnclick("A")}
          >
            Personal Information
          </button>
          <button
            style={{
              background:
                act === "B"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "B" ? "#00A849" : "#FFFFFF",
              border:
                act === "B"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/event-tickets") ? "active" : ""}
            onClick={() => handlebtnclick("B")}
          >
            Event Tickets
          </button>
          <button
            style={{
              background:
                act === "C"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "C" ? "#00A849" : "#FFFFFF",
              border:
                act === "C"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/nft-rewards") ? "active" : ""}
            onClick={() => handlebtnclick("C")}
          >
           MRT Rewards
          </button>
          <button
            style={{
              background:
                act === "D"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "D" ? "#00A849" : "#FFFFFF",
              border:
                act === "D"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/collectibles-owned") ? "active" : ""}
            onClick={() => handlebtnclick("D")}
          >
            Collectibles Owned
          </button>
          <button
            style={{
              background:
                act === "E"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "E" ? "#00A849" : "#FFFFFF",
              border:
                act === "E"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/photo-roll") ? "active" : ""}
            onClick={() => handlebtnclick("E")}
          >
            Photo Roll
          </button>
          <button
            style={{
              background:
                act === "F"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "F" ? "#00A849" : "#FFFFFF",
              border:
                act === "F"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/liked-events") ? "active" : ""}
            onClick={() => handlebtnclick("F")}
          >
            Liked Events
          </button>
          <button
            style={{
              background:
                act === "G"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: act === "G" ? "#00A849" : "#FFFFFF",
              border:
                act === "G"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            className={isActive("/following") ? "active" : ""}
            onClick={() => handlebtnclick("G")}
          >
            Following
          </button>
        </div>
      </div>
      {act === "A" && (
        <>
          <div className="profile-details">
            <div className="profile-picture">
              <p>Profile Picture</p>
              <img src={personalinfo?.profilePicture} alt="Profile" />
            </div>
            <div className="profile-details-text-heading">
              <p>Name</p>
              <h2>

                {personalinfo?.fullname}
              </h2>
            </div>
            <div className="profile-details-text-heading">
              <p>Email</p>
              <h2>{personalinfo?.email === "" ? "Not Linked" : personalinfo?.email}</h2>
            </div>

            <div className="profile-details-text-heading">
              <p>Personal Social Pofile</p>
              <button

                className="link-button-between">Link</button>
            </div>
            <div className="profile-details-text-heading">
              <p>Facebook</p>
              <h2>{personalinfo?.fbUrl === null ? "No Link" : personalinfo?.fbUrl}</h2>
            </div>
            <div className="profile-details-text-heading">
              <p>Instagram</p>
              <h2>{personalinfo?.instaUrl === null ? "No Link" : personalinfo?.instaUrl}</h2>
            </div>
            <div className="profile-details-text-heading">
              <p>Telegram</p>
              <h2>{personalinfo?.fbUrl === null ? "No Link" : personalinfo?.fbUrl}</h2>
            </div>

            <div className="profile-details-text-heading">
              <p>LinkedIn</p>
              <h2>{personalinfo?.linkedinUrl === null ? "No Link" : personalinfo?.linkedinUrl}</h2>
            </div>
            <div className="profile-details-text-heading">
              <p>Youtube</p>
              <h2>{personalinfo?.youtubeUrl === null ? "No Link" : personalinfo?.youtubeUrl}</h2>
            </div>
            <div className="profile-details-text-heading">
              <p>TikTok</p>
              <h2>{personalinfo?.tiktokUrl === null ? "No Link" : personalinfo?.tiktokUrl}</h2>
            </div>
            <div className="profile-details-text-heading">
              <p>Twitter</p>
              <h2>{personalinfo?.twitterUrl === null ? "No Link" : personalinfo?.twitterUrl}</h2>
            </div>
          </div>

          <button className='Organizer_detail_Follower__Btn' onClick={handleExportCSV}  >
            Export
          </button>
        </>
      )}
      {act === "B" && (
        <>
          <EventTicket event={eventTicket} />
        </>
      )}
      {act === "C" && (
        <>
          <MrtRewards reward={rewardinfo} />
        </>
      )}
      {act === "D" && (
        <>
          <CollectiblesOwned collectibles={userCollectibles} />
        </>
      )}
      {act === "E" && (
        <>
          <PhotoRoll photoData={photoRoll} />
        </>
      )}
      {act === "F" && (
        <>
          <Organiser_Detail_Liked_Events likeEvent={likedEventData} />
        </>
      )}
      {act === "G" && (
        <>
          <FollowingTicket followingData={organizerData}/>
        </>
      )}
    </div>
    </>
  );
};

export default PersonalInfoDetail;
