import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserCollectibles } from "../middleware/getUserCollectibles";

const initialState = {
  loading: false,
  error: "",
  userCollectiblesData : [],
};
const getUserCollectiblesSlice = createSlice({
  name: "getUserCollectibles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserCollectibles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserCollectibles.fulfilled, (state, action) => {
      state.loading = false;
      state.userCollectiblesData = action.payload;
    });
    builder.addCase(getUserCollectibles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getUserCollectiblesSlice.reducer;