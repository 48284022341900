import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import "./SalesAnalytics_table_3.css"

const SalesAnalytics_table_3 = (saleData) => {
  
 // Sample data as shown in the image
 const data = [
    { id: 1, ticketType: 'Premium', sold: 100, percentage: "90%" },
    { id: 2, ticketType: 'Gold', sold: 80, percentage: "78%" },
    { id: 3, ticketType: 'Silver', sold: 150, percentage: "120%" },
    { id: 4, ticketType: 'Silver', sold: 40, percentage: "120%" },
  ];

  const [isExpanded, setIsExpanded] = useState(false); 
  const [ticketTypes, setTicketTypes] = useState();
  const handleToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded); 
  };
  useEffect(() => {
    if (saleData?.salesData?.getLiveEvent?.data?.ticketTypes) {
      console.log(
        "Sales data for third table is .......",
        saleData.salesData.getLiveEvent.data.ticketTypes
      );
      setTicketTypes(saleData.salesData.getLiveEvent.data.ticketTypes);
    }
  }, [saleData]);

  return (

<div className='SalesAnalytics_table1___main'>
      <div   className='SalesAnalytics_table1___main_Firstttt'>

{/* <div  className='SalesAnalytics_table1___main_Firstttt_1'>
<p className='SalesAnalytics_table1___main_Firstttt_1_p1'>Tickets Sold</p>
<p className='SalesAnalytics_table1___main_Firstttt_1_p2'>330</p>
</div> */}
<p className='SalesAnalytics_table1___main_Firstttt____ppp'> Scanned  Ticket Categories</p>



<div className='SalesAnalytics_table1___main_Firstttt_2' > <img src='/Payout_Images/1.svg' alt='..'/></div>


      </div>


      <TableContainer  style={{
        display: isExpanded ? 'flex' : 'none',}}
       component={Paper}  className='SalesAnalytics_table1___MAIN___111'>
    <Table sx={{minWidth:650}} >
      <TableHead >
        <TableRow className='SalesAnalytics_table1___MAIN'>
          <TableCell  className='SalesAnalytics_table1___Ptags'>#</TableCell>
          <TableCell  className='SalesAnalytics_table1___Ptags'>Ticket Categories</TableCell>
          <TableCell  className='SalesAnalytics_table1___Ptags'> Scanned</TableCell>
          <TableCell  className='SalesAnalytics_table1___Ptags'>Attendees</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      <TableRow>
    <TableCell colSpan={4} style={{ height: '16px', backgroundColor: 'transparent', borderBottom:"none" , padding:"0px"}}></TableCell>
  </TableRow>

        {ticketTypes?.map((row,index) => (
          <TableRow key={row.id}>
            <TableCell  className='SalesAnalytics_table1___Ptags2' style={{width:"70px"}} >{index}</TableCell>
            <TableCell className='SalesAnalytics_table1___Ptags2'style={{width:"320px"}}  >{row?.type}</TableCell>
            <TableCell  className='SalesAnalytics_table1___Ptags2' style={{width:"213px"}} >{row?.scanCount}</TableCell>
            <TableCell  className='SalesAnalytics_table1___Ptags2' style={{width:"233px"}} >{row?.userCount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>




  <div className="expandimge____">
        <img src={isExpanded ? "/Payout_Images/minimize.svg":"/Payout_Images/Back - Button.svg" }
        alt="" 
         style={{ cursor: 'pointer' ,position:"absolute"}}
         onClick={handleToggle}
        />
      </div>

    </div>



  )
}

export default SalesAnalytics_table_3
