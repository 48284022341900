import React from "react";
import { useEffect, useState } from "react";
import "./Organiser_Detail_Liked_Events__Table.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ScreenLoader from "../loader/screenLoader";
const Organiser_Detail_Liked_Events__Table = (likedEvents) => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    try {
      setEventsData(
        likedEvents?.likedEvents?.getLikedEventsData?.data?.events || []
      );
      console.log(
        "liked events data is .......",
        likedEvents?.likedEvents?.getLikedEventsData?.data?.events
      );
    } catch (error) {
      console.error("Error occurred while setting events data:", error);
    } finally {
      setLoading(false);
    }
  }, [likedEvents]);
  

  const generateCSVData = () => {
    const headers = ["Picture URL", "Event Name", "Organiser Name", "Category", "Likes"];
    const rows = eventsData.map((row) => [
      row?.organizerProfile?.profilePicture || "----",
      row?.name || "----",
      row?.organizerProfile?.name || "----",
      row?.category?.join(", ") || "----",
      row?.likeCount || "0",
    ]);

    const csvContent = [
      headers.join(","), 
      ...rows.map((row) => row.join(","))
    ].join("\n");

    return csvContent;
  };


  const downloadCSV = () => {
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "liked_events_data.csv";
    link.click();
  };

  return (
    <>
     {loading && <ScreenLoader />}
    <div>
      <TableContainer
        component={Paper}
        className="Organizer_detail_Follower_Main"
      >
        <Table sx={{ minWidth: 750, maxWidth: 1020 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Picture
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Event Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Organiser Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Category
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Likes
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>
            {eventsData?.map((row, index) => (
              <TableRow
                key={index}
                className="rganizer_detail_Follower_Main___Body_Top"
              >
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  <img
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "8px",
                    }}
                    src={row?.organizerProfile?.profilePicture}
                    alt=".."
                  />
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  {row?.name ? row?.name:"---"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.organizerProfile?.name ?row?.organizerProfile?.name:"----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.category?.map((cat, index) => (
                    <span key={index}>
                      {cat}
                      {index < row.category.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.likeCount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <button onClick={downloadCSV} className="User__detail_Scanner___Btn">Export</button>
    </div>
    </>
  );
};

export default Organiser_Detail_Liked_Events__Table;
