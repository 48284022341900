import React from "react";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./Organizer_Detail_Scanner_login.css";
import { useDispatch, useSelector } from "react-redux";
import { getScanner } from "../../services/redux/middleware/getScanners";
import ScreenLoader from "../loader/screenLoader";
const Organizer_Detail_Scanner_login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const ScannerData = useSelector((state) => state?.getScanner);
  const scannerDetails = ScannerData?.scannerData?.data?.data || []; 

  useEffect(() => {
    setLoading(true);
    dispatch(getScanner())
    .finally(() => setLoading(false));
  }, [dispatch]);
  const generateCSVData = () => {
    const headers = ["Name", "Email", "Scanned Event", "Following"];
    const rows = scannerDetails.map((row) => [
      row?.name || "----",
      row?.email || "----",
      row?.scanCount || "----",
      row?.totalFollowers || "----"
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(","))
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "scanner_data.csv";
    link.click();
  };
  return (
    <>
      {loading && <ScreenLoader />}
    <div>
      <TableContainer
        component={Paper}
        className="Organizer_detail_Follower_Main"
      >
        <Table sx={{ minWidth: 750, maxWidth: 915 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="Organizer_detail_Follower_Main___Row ">
                Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Email
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Scanned Event
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Following
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>

            {ScannerData?.scannerData?.data?.data?.map((row, index) => (
              <TableRow
                key={index}
                className="rganizer_detail_Follower_Main___Body_Top"
              >
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  {row?.name}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.email}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.scanCount}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.totalFollowers}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <button onClick={downloadCSV} className="Organizer_detail_Follower__Btn">Export</button>
    </div>
    </>
  );
};

export default Organizer_Detail_Scanner_login;
