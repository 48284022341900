import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getOrganizerById= createAsyncThunk("getOrganizerById", async (data) => {
  const { id } = data; 
  try {
    const res = await api.get(`${API_URL}/auth/getOrganizerById/${id}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

