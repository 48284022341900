import React from "react";
import { useEffect, useState } from "react";
import "./User_Updated_Table.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserById } from "../../services/redux/middleware/getallUser";
import { getAllUserWithId } from "../../services/redux/middleware/getAllUserById";
import ScreenLoader from "../../Components/loader/screenLoader";

const User_Updated_Table = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleNavigate = (id) => {
    navigate(`/PersonalInfoDetail/${id}`);
  };
  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  };
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.getAllUser);
  console.log("received data is...", userData);

  useEffect(() => {
    setLoading(true); 
    dispatch(getAllUserById())
      .finally(() => setLoading(false)); 
  }, [dispatch]);

  const handleExport = () => {
    const headers = [
      "PFP",
      "Name",
      "Email",
      "Role",
      "Date Joined",
      "Tickets Bought",
      "Events Attended",
    ];

    const rows = userData?.userData?.data?.map((data) => [
      data?.profilePicture,
      data?.fullname || "----",
      data?.email || "----",
      data?.role || "----",
      data?.dateJoining || "----",
      data?.totalTicketsBought || "----",
      data?.eventsAttended || "----",
    ]);
    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += headers.join(",") + "\n";
    rows.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_data.csv");
    link.click();
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
     {loading && <ScreenLoader />}
      <div className="personal-info-detail-container____">
        <Box>
          <Box sx={{ backgroundColor: "#000", mb: "16px" }}></Box>
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                    className="even-name-text-style-for"
                  >
                    PFP
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "195px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "105px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Date Joined
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Tickets Bought
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Events Attended
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "141px",
                    }}
                    className="even-name-text-style-for"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow
                sx={{
                  height: "16px",
                  backgroundColor: "#030303",
                  width: "84px",
                }}
              >
                <TableCell colSpan={8}></TableCell>
              </TableRow>
              <TableBody className="for-to-give-the-padding">
                {userData?.userData?.data?.map((data, index) => (
                  <TableRow
                    key={index}
                    className="main-even-name-text-style-for"
                  >
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "85px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "1000px",
                        }}
                        src={data?.profilePicture}
                        alt=""
                      />
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      {data?.fullname ?data?.fullname:"----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "195px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.email ?data?.email:"----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.role ? data?.role:"----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.dateJoining ? data?.dateJoining:"----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.totalTicketsBought ?data?.totalTicketsBought:"----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "141px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {data?.eventsAttended ?data?.eventsAttended:"----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "84px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                      onClick={() => handleNavigate(data?.id)}
                    >
                      <img
                        src="/Images/User_Updated_table/eye_updated.svg"
                        alt="Eye View"
                        style={{ width: "24px" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <div className="export-button">
        <button onClick={handleExport}>Export</button>
      </div>
      </div>
    </>
  );
};
export default User_Updated_Table;