import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLiveEvent } from "../middleware/getLiveEvent";

const initialState = {
  loading: false,
  error: "",
  getLiveEventData : [],
};
const getLiveEventSlice = createSlice({
  name: "getLiveEvent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLiveEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLiveEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.getLiveEvent = action.payload;
    });
    builder.addCase(getLiveEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getLiveEventSlice.reducer;