import React from "react";
import { useState, useEffect } from "react";
import User_Updated_Table from '../User_Updated_Table/User_Updated_Table'
import Updated_Scanner_Updated_Table from '../Updated_Scanner_Updated_Table/Updated_Scanner_Updated_Table'
import "./user.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "../../Assets/Dashboard/Avatar.svg"; // Adjust the path as necessary
import View from "../../Assets/Dashboard/eye.svg";
import User_Scanner_Scanner from "../../Components/User_Scanner_Scanner/User_Scanner_Scanner";
function createData(
  name,
  email,
  role,
  dateJoined,
  ticketsBought,
  eventsAttended,
  avatar,
  view
) {
  return {
    name,
    email,
    role,
    dateJoined,
    ticketsBought,
    eventsAttended,
    avatar,
    view,
  };
}
function OrganiserData(
  name,
  email,
  role,
  dateJoined,
  hostedEvents,
  avatar,
  view
) {
  return { name, email, role, dateJoined, hostedEvents, avatar, view };
}
function ScannerData(name, email, role, dateJoined, scanTickets, avatar, view) {
  return { name, email, role, dateJoined, scanTickets, avatar, view };
}

const Users = () => {
  const [selectedView, setSelectedView] = useState("Simple User");

  // Retrieve selectedView from localStorage when the component mounts
  useEffect(() => {
    const savedView = localStorage.getItem("selectedView");
    if (savedView) {
      setSelectedView(savedView);
    }
  }, []);
  const handleButtonClick = (view) => {
    setSelectedView(view);
    localStorage.setItem("selectedView", view);
  };
  return (
    <div className="UsersUsers">
    <div className="UsersButtons">
      <button
        className="simpleUserButton"
        style={{
          background:
            selectedView === "Simple User"
              ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
              : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
          color: selectedView === "Simple User" ? "#00A849" : "#FFFFFF",
          border: "1px solid transparent",
        }}
        onClick={() => handleButtonClick("Simple User")}
      >
        Simple User
      </button>
      <button
        className="simpleUserButton"
        style={{
          background:
            selectedView === "Organiser"
              ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
              : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
          color: selectedView === "Organiser" ? "#00A849" : "#FFFFFF",
          border: "1px solid transparent",
        }}
        onClick={() => handleButtonClick("Organiser")}
      >
        Organiser
      </button>
      <button
        className="simpleUserButton"
        style={{
          background:
            selectedView === "Scanner"
              ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%) border-box"
              : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%) padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%) border-box",
          color: selectedView === "Scanner" ? "#00A849" : "#FFFFFF",
          border: "1px solid transparent",
        }}
        onClick={() => handleButtonClick("Scanner")}
      >
        Scanner
      </button>
    </div>
    {selectedView === "Simple User" && (
      <>
        <User_Updated_Table />
        {/* <button className="Export">Export</button> */}
      </>
    )}
    {selectedView === "Organiser" && (
      <>
        <User_Scanner_Scanner />
        {/* <Updated_Scanner_Updated_Table/> */}
        {/* <button className="Export">Export</button> */}
      </>
    )}
    {selectedView === "Scanner" && (
      <>
        <Updated_Scanner_Updated_Table />
        {/* <button className="Export">Export</button> */}
      </>
    )}
  </div>
  
  );
};

export default Users;
