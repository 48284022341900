import React, { useState } from "react";
import './SalesAnalyst-Graph.css';

import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, Legend);
const SalesAnalyst_Graph= () => {
    const [isExpanded, setIsExpanded] = useState(false); 

    const handleToggle = () => {
      setIsExpanded((prevExpanded) => !prevExpanded); 
    };
  const data = {
    labels: ['Jan', '', 'Mar', '', 'May', '', 'Jul'],
    datasets: [
      {
        type: 'bar',
        label: 'Sales',
        data: [7, 10, 8, 9, 6, 8, 6],
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(0, 208, 89, 0.32)');
          gradient.addColorStop(1, 'rgba(0, 208, 89, 0)');
          return gradient;
        },
        borderColor: '#00D059', // Top border color
        borderWidth: { top: 5.14 }, // Border width at the top
        borderRadius: 0,
        barPercentage: 0.9,
      },
      {
        type: 'line',
        label: 'Trend',
        data: [5, 8, 6, 9, 5, 6, 3],
        borderColor: '#00D059', // Updated line color
        borderWidth: 3,
        tension: 0.5,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    
    scales: {
      x: {
        ticks: {
          font: {
            family: "Nexa-Regular", // Font family
            size: 30.85,           // Font size
            weight: 400,           // Font weight
          },
          color: " #FFFFFF",           // Label color
        },
        grid: { display: false },
      },
      y: {
        display: false,
        grid: { display: false },
      },
    },
    plugins: {
      legend: { display: false },
    },
    layout: {
      padding: {
        top: 20,
        left: 10,
        right: 10,
        bottom: 24,
      },
    },
  

};
  return (
    <div  className='Gross_sales' style={{ width: '904px', height: '613px', borderRadius: '8px', padding: '24px' }}>
      <h4 className='H4_Text'>Gross Sales</h4>
      <Chart className='BRLINES' type="bar" data={data} options={options} />
      
    </div>
  );
};
export default SalesAnalyst_Graph;