import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getKycApproval } from "../middleware/getKyc";

const initialState = {
  loading: false,
  error: "",
  kycApprovalData : [],
};
const getKycApprovalSlice = createSlice({
  name: "getKycApproval",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getKycApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKycApproval.fulfilled, (state, action) => {
      state.loading = false;
      state.kycApprovalData = action.payload;
    });
    builder.addCase(getKycApproval.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getKycApprovalSlice.reducer;