import './GetPaidRequest_After_Qa2.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const data = [
    { id: 1, item: 'Tickets Sold', amount: '£100,000' },
    { id: 2, item: 'Platform Fees', amount: '£100,000' },
    { id: 3, item: 'Payout Available', amount: '£100,000' },
  ];
const GetPaidRequest_After_Qa2 =()=>{
    
    return(
<div className="paidRequests">
    <div className='Maindiv1'>
    <div className='Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image'>
        <img src='/Payout_Images/graphlogo.svg' />
        <h2>Amount</h2>
      </div>
    <div className='firstTable'>
        <p className="FundRaied">Funds Raised</p>
        <p className='Value'>
        £250,000
        </p>
        <TableContainer      style={{
        display:  'flex'}}
      component={Paper}  className='SalesAnalytics_table1___MAIN___11'>
    <Table sx={{minWidth:650}} >
      <TableHead >
        <TableRow className='SalesAnalytics_table1___MAIN'>
          <TableCell sx={{borderBottom:"none" }} className='SalesAnalytics_table1___Ptags'>#</TableCell>
          <TableCell sx={{borderBottom:"none" }} className='SalesAnalytics_table1___Ptags'>Item</TableCell>
          <TableCell sx={{borderBottom:"none" }} className='SalesAnalytics_table1___Ptags'>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      <TableRow>
    <TableCell colSpan={4} style={{ height: '16px', backgroundColor: 'transparent', borderBottom:"none" , padding:"0px"}}></TableCell>
  </TableRow>
        {data.map((row) => (
          <TableRow key={row.id}>
            <TableCell sx={{borderBottom:"none" ,fontFamily:"Nexa", fontWeight:"400" ,fontSize:'14px', lineHeight:'20.3px', color:'rgba(255, 255, 255, 1)'}} className='SalesAnalytics_table1___Ptags2'  style={{width:"70px"}}>{row.id}</TableCell>
            <TableCell sx={{borderBottom:"none" ,fontFamily:"Nexa", fontWeight:"700" ,fontSize:'14px', lineHeight:'20.3px', color:'rgba(255, 255, 255, 1)'}}className='SalesAnalytics_table1___Ptags2'  style={{width:"320px"}}>{row.item}</TableCell>
            <TableCell sx={{borderBottom:"none" ,fontFamily:"Nexa", fontWeight:"400" ,fontSize:'14px', lineHeight:'20.3px', color:'rgba(255, 255, 255, 1)'}} className='SalesAnalytics_table1___Ptags2' style={{width:"213px"}}>{row.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</div>
    </div>
    <div className='maindiv2'>
        <p className='withdrawldetails'>
        Withdrawal Details
        </p>
        <div className='Table2'>
            <div className='row1'>
                <p className='bankName'>Bank Name</p>
                <p className='bank'>Lloyd Bank</p>
            </div>
            <div className='row1'>
                <p className='bankName'>Title of Account</p>
                <p className='bank'>John Williams</p>
            </div>
            <div className='row1'>
                <p className='bankName'>Account Number</p>
                <p className='bank'>126283399384039</p>
            </div>
            <div className='row1'>
                <p className='bankName'>Country/City</p>
                <p className='bank'>USA/Texas</p>
            </div>
        </div>
    </div>
    <div className='buttons'>
        <button className='button1'>
        Approve
        </button>
        <button className='button2'>
            Deny
        </button>
    </div>
</div>
    );
};
export default GetPaidRequest_After_Qa2;