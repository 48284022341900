import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import "./Organizer_Detail_Follower.css";
import ScreenLoader from "../loader/screenLoader";
const Organizer_Detail_Follower = (followersData) => {
  const [loading, setLoading] = useState(true);
  const [followersDetailData, setFollowersDetailData] = useState([]);

  useEffect(() => {
    setLoading(true);
    try {
      if (followersData?.followersData?.userCollectiblesData?.data?.data) {
        setFollowersDetailData(followersData?.followersData?.userCollectiblesData?.data?.data?.followerDetails || []);
      }
    } catch (error) {
      console.error("Error occurred while fetching followers data:", error);
    } finally {
      setLoading(false);
    }
  }, [followersData]);
  

  console.log("followersDetailData......", followersData?.followersData?.userCollectiblesData?.data?.data?.followerDetails); 
  const headers = [
    'Follower Name', 
    'Email', 
    'Bought Tickets', 
    'Attended Events', 
    'Following'
  ];

  const data = followersDetailData?.map((row) => [
    row?.name || "---",
    row?.email || "---",
    row?.ticketsBought || "---",
    row?.attendedEvents || "---",
    followersDetailData?.length || "---",
  ]);

  const generateCSV = () => {
    const csvContent = [
      headers.join(','), 
      ...data.map(row => row.join(',')) 
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', 'followers_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
     {loading && <ScreenLoader />}
    <div className='personal-info-detail-container-----sj'>
      <TableContainer component={Paper} className='Organizer_detail_Follower_Main'>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className='Organizer_detail_Follower_Main___Row'>Follower Name</TableCell>
              <TableCell className='Organizer_detail_Follower_Main___Row'>Email</TableCell>
              <TableCell className='Organizer_detail_Follower_Main___Row'>Bought Tickets</TableCell>
              <TableCell className='Organizer_detail_Follower_Main___Row'>Attended Events</TableCell>
              <TableCell className='Organizer_detail_Follower_Main___Row'>Following</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={5} style={{ height: '16px', backgroundColor: 'transparent', borderBottom: "none", padding: "0px" }}></TableCell>
            </TableRow>
            {followersDetailData?.map((row, index) => (
              <TableRow key={index} className='Organizer_detail_Follower_Main___Body_Top'>
                <TableCell className='Organizer_detail_Follower_Main___Body'>{row?.name ?row?.name:"---"}</TableCell>
                <TableCell className='Organizer_detail_Follower_Main___Body1'>{row?.email ?row?.email:"---"}</TableCell>
                <TableCell className='Organizer_detail_Follower_Main___Body1'>{row?.ticketsBought ? row?.ticketsBought:"---"}</TableCell>
                <TableCell className='Organizer_detail_Follower_Main___Body1'>{row?.attendedEvents ? row?.attendedEvents:"---"}</TableCell>
                <TableCell className='Organizer_detail_Follower_Main___Body1'>{followersDetailData?.length ?followersDetailData?.length:"---"}</TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      <button onClick={generateCSV}className='Organizer_detail_Follower__Btn'>
        Export
      </button>
    </div>
    </>
  );
};

export default Organizer_Detail_Follower;
