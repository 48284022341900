import React from "react";
import {useEffect, useState} from "react"
// import "./EventTicket.css";
import "./Hosted_Events_Attendeed_main.css";
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../loader/screenLoader";

const Hosted_Events_Attendeed_main = (hostedEvent) => {
  const navigate = useNavigate();
  const handleNavigate = (eventId) => {
    navigate(`/Attendeed_Hosted_Events/${eventId}`);
  };
  const handleNavigate2 = (eventId) => {
    navigate(`/Organiser_Detail_HostedEvents_Sales_Analytics/${eventId}`);
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

console.log("hostedEvent",hostedEvent?.hostedEvent?.hostedEvents?.data?.data?.events)
const generateCSV = () => {
  const headers = [
    "Event Name",
    "Location",
    "Ticket Types & Price",
    "Start Date",
    "End Date",
    "Attendees",
    "Sales Analytics",
    "Link"
  ];

  const rows = hostedEvent?.hostedEvent?.hostedEvents?.data?.data?.events?.map((event) => [
    event?.name || "N/A",
    event?.location || "N/A",
    event?.ticketType + (event?.ticketPrice ? ` (${event.ticketPrice})` : "") || "N/A",
    event?.startTime?.split("T")[0] || "N/A",
    event?.endTime?.split("T")[0] || "N/A",
    "", 
    ""  
  ]);

  const csvContent = [
    headers.join(","),
    ...rows.map(row => row.join(","))
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "hosted_events_data.csv"; 
  link.click(); 
};
useEffect(() => {

  setLoading(false); 
}, []);
  return (
    <>
        {loading && <ScreenLoader />}
    <div className="personal-info-detail-container-even-ticket">
      
      <div className="EventTicket-to-give-max-width-attendeed-main">
        <Box>
          <Box
            sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
          ></Box>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" , width:'240px'}}
                    className="even-name-text-style-for"
                  >
                    Event Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929"  , width:'140px' }}
                    className="even-name-text-style-for"
                  >
                    Location
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'220px' }}
                    className="even-name-text-style-for"
                  >
                    Ticket Types & Price
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'120px' }}
                    className="even-name-text-style-for"
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"   , width:'120px'}}
                    className="even-name-text-style-for"
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'140px'}}
                    className="even-name-text-style-for"
                  >
                    Attendees
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"   , width:'150px'}}
                    className="even-name-text-style-for"
                  >
                    Sales Analytics
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)"  , width:'110px' }}
                    className="even-name-text-style-for"
                  >
                    Link
                    
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Spacer row for gap */}
              <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                <TableCell colSpan={8}></TableCell>
              </TableRow>

              <TableBody>
                {hostedEvent?.hostedEvent?.hostedEvents?.data?.data?.events?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="main-even-name-text-style-for"
                  >
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      {row?.name}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.location}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.ticketType}
                      {row?.ticketPrice ? ` (${row.ticketPrice})` : ""}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.startTime?.split("T")[0]}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.endTime?.split("T")[0]}
                    </TableCell>
                    {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929" ,width:"170px"}}
                    className="main-even-name-text-style-for-padding-margin-type-false-true"
                  >
                    {row.attended ? (
                      <Check sx={{ color: "rgba(0, 208, 89, 1)" }} />
                    ) : (
                      <Close sx={{ color: "rgba(255, 23, 23, 1)" }} />
                    )}
                  </TableCell> */}
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type-to-give-color"
                      onClick={()=>handleNavigate(row?.id)}
                      >
                      View Details
                      
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type-to-give-color"
                       onClick={()=>handleNavigate2(row?.id)}
                   >
                     View Details
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      <img
                      src="/Images/HostedEventsAtendeedMain/arrow.svg"
                      alt="Event"
                      style={{ width: "20px" }}
                    />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>

      <div className="export-button">
        <button onClick={generateCSV}>Export</button>
      </div>
    </div>
    </>
  );
};

export default Hosted_Events_Attendeed_main;
