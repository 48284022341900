import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getOrganizerById } from "../middleware/getOrganizerById";

const initialState = {
  loading: false,
  error: "",
  getOrganizerByIdData: [],
};
const  getOrganizerByIdSlice = createSlice({
  name: "getOrganizerById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizerById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrganizerById.fulfilled, (state, action) => {
      state.loading = false;
      state.getOrganizerByIdData = action.payload;
    });
    builder.addCase(getOrganizerById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getOrganizerByIdSlice.reducer;