import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getKycById } from "../middleware/getKycById";

const initialState = {
  loading: false,
  error: "",
  kycRequestData : [],
};
const getKycByIdSlice = createSlice({
  name: "getKycById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getKycById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKycById.fulfilled, (state, action) => {
      state.loading = false;
      state.kycRequestData = action.payload;
    });
    builder.addCase(getKycById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getKycByIdSlice.reducer;