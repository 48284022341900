import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./KycAndAproval.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getKycApproval } from "../../services/redux/middleware/getKyc";
import ScreenLoader from "../loader/screenLoader";
function KycAndAproval() {
  const theme = useTheme();
  const { kycId } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const kycApprovalData = useSelector(
    (state) => state?.getKycApproval?.kycApprovalData?.data?.data
  );
  console.log("kyc approval data  is...", kycApprovalData);

  useEffect(() => {
    setLoading(true);
    dispatch(getKycApproval())
    .finally(() => setLoading(false));
  }, [dispatch]);

  const handleExportCSV = () => {
    if (!kycApprovalData || kycApprovalData.length === 0) {
      alert("No data available to export");
      return;
    }

    const headers = [
      "Name",
      "Email",
      "DOB",
      "Role",
      "Website",
      "Date",
      "Status",
    ];
    const rows = kycApprovalData.map((row) => [
      `${row?.Individual?.[0]?.FirstName || ""} ${
        row?.Individual?.[0]?.LastName || ""
      }`.trim() || "---",
      row?.Individual?.[0]?.Email || "---",
      row?.Individual?.[0]?.DOB || "---",
      row?.userType || "----",
      row?.companyWebsite || "----",
      row?.createdAt.split("T")[0],
      row?.approved ? "true" : "false",
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "kyc_approval_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      {loading && <ScreenLoader />}
    <div className="personal-info-detail-container-----FOLLOWING">
      <div className="given-to-the-width-to-all">
        <Box>
          <Box
            sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
          ></Box>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    DOB
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Website
                  </TableCell>

                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                    className="even-name-text-style-for"
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Spacer row for gap */}
              <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                <TableCell colSpan={7}></TableCell>
              </TableRow>

              <TableBody>
                {kycApprovalData?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="main-even-name-text-style-for"
                  >
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      {`${row?.Individual?.[0]?.FirstName || ""} ${
                        row?.Individual?.[0]?.LastName || ""
                      }`.trim() || "---"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.Individual?.[0]?.Email || "---"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.Individual?.[0]?.DOB || "---"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.userType ? row?.userType : "----"}
                    </TableCell>
                    {/* <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="main-even-name-text-style-for-padding-margin-type-false-true"
                  >
                    {row.attended ? (
                      <Check sx={{ color: "rgba(0, 208, 89, 1)" }} />
                    ) : (
                      <Close sx={{ color: "rgba(255, 23, 23, 1)" }} />
                    )}
                  </TableCell> */}
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.companyWebsite ? row?.companyWebsite : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.createdAt.split("T")[0]}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.approved ? "true" : "false"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>

      <div className="export-button">
        <button onClick={handleExportCSV}>Export</button>
      </div>
    </div>
    </>
  );
}

export default KycAndAproval;
