import React from 'react'
import './BusnesDetail.css'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const data = [
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  {
    eventName: "John williams",
    ticketType: "Lorem Ispum",
    price: "1234567890",
    date: "www.xyz.com",
    attended: false,
    time: "Lorem Ispum",
    Towncity:"California",
    Country:"USA"
  },
  // Add more rows as necessary...
];
function BusnesDetailTable() {
    const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="personal-info-detail-container-----FOLLOWING">
   
  <div className="given-to-the-width-to-all">
  <Box>
      <Box sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}></Box>

      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.06)",
          overflowX: "auto",
        
        }}
      >
        <Table>
          <TableHead
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
            className="to-apply-table-border-color"
          >
            <TableRow>
              <TableCell
                sx={{ borderBottom: "1px solid #292929" }}
                className="even-name-text-style-for"
              >
             Company Name
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid #292929" }}
                className="even-name-text-style-for"
              >
              Business Type
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                className="even-name-text-style-for"
              >
              UTR
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                className="even-name-text-style-for"
              >
              Website
              </TableCell>
            
              <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                className="even-name-text-style-for"
              >
               Address
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                className="even-name-text-style-for"
              >
               Town/City
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                className="even-name-text-style-for"
              >
               Country
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Spacer row for gap */}
          <TableRow sx={{ height: "16px", backgroundColor: "#030303" , }}>
            <TableCell colSpan={7}></TableCell>
          </TableRow>

          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className="main-even-name-text-style-for">
                <TableCell
                  sx={{ borderBottom: "1px solid #292929" ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-name"
                >
                  {row.eventName}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929"  ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-type"
                >
                  {row.ticketType}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929"  ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-type"
                >
                  {row.price}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929"  ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-type"
                >
                  {row.date}
                </TableCell>
                {/* <TableCell
                  sx={{ borderBottom: "1px solid #292929" }}
                  className="main-even-name-text-style-for-padding-margin-type-false-true"
                >
                  {row.attended ? (
                    <Check sx={{ color: "rgba(0, 208, 89, 1)" }} />
                  ) : (
                    <Close sx={{ color: "rgba(255, 23, 23, 1)" }} />
                  )}
                </TableCell> */}
                <TableCell
                  sx={{ borderBottom: "1px solid #292929"  ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-type"
                >
                  {row.time}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929"  ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-type"
                >
                  {row.Towncity}
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929"  ,width:'275px'}}
                  className="main-even-name-text-style-for-padding-margin-type"
                >
                  {row.Country}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </div>
   

    <div className="export-button">
      <button>Export</button>
    </div>
  </div>
    
  )
}

export default BusnesDetailTable