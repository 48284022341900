import React, { useState, useEffect } from "react";
import './MrtRewards.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";

const MrtRewards = (reward) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (reward?.reward?.getClaimUserData?.data?.data) {
      setLoading(false);  
    }
  }, [reward?.reward?.getClaimUserData?.data?.data]);

  console.log("my reward data is ", reward?.reward?.getClaimUserData?.data?.data);

  const exportToCSV = () => {
    const csvHeaders = ["Reward Name", "MRT Reward", "Date", "Time"];
    const csvRows = reward?.reward?.getClaimUserData?.data?.data?.map((row) => [
      row?.claim?.claimText || "----",
      row?.claim?.claimAmount || "----",
      row?.claim?.claimedAt?.split("T")[0] || "----",
      row?.claim?.claimedAt?.split("T")[1]?.split(".")[0] || "----",
    ]);
    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "MrtRewards_History.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="personal-info-detail-container-mrt-rewards">
   
      {loading ? (
        <ScreenLoader />
      ) : (
        <>
          <div className="mtr-owned-center-part">
            <p>MRT Owned</p>
            <h2 className="smjh-se-bahr-q">$1025.56 MRT</h2>
          </div>

          <div className="box-main-both-head-and-body">
            <Box>
              <Box sx={{ height: "30px", backgroundColor: "#000", mb: "16px" }}></Box>

              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                    className="to-apply-table-border-color"
                  >
                    <TableRow>
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929", width: "275px" }}
                        className="even-name-text-style-for"
                      >
                        Reward Name
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)", width: "275px" }}
                        className="even-name-text-style-for"
                      >
                        MRT Reward
                      </TableCell>
                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)", width: "275px" }}
                        className="even-name-text-style-for"
                      >
                        Date
                      </TableCell>

                      <TableCell
                        sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)", width: "275px" }}
                        className="even-name-text-style-for"
                      >
                        Time
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>

                  <TableBody>
                    {reward?.reward?.getClaimUserData?.data?.data?.map((row, index) => (
                      <TableRow key={index} className="main-even-name-text-style-for">
                        <TableCell
                          sx={{ borderBottom: "1px solid #292929" }}
                          className="main-even-name-text-style-for-padding-margin-name"
                        >
                          {row?.claim?.claimText}
                        </TableCell>

                        <TableCell
                          sx={{ borderBottom: "1px solid #292929" }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.claim?.claimAmount}
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "1px solid #292929" }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.claim?.claimedAt?.split("T")[0]}
                        </TableCell>

                        <TableCell
                          sx={{ borderBottom: "1px solid #292929" }}
                          className="main-even-name-text-style-for-padding-margin-type"
                        >
                          {row?.claim?.claimedAt?.split("T")[1]?.split(".")[0]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          <div className="export-button">
            <button onClick={exportToCSV}>Export</button>
          </div>
        </>
      )}
    </div>
  );
};

export default MrtRewards;
