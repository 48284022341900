import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllUserWithId } from "../middleware/getAllUserById";


const initialState = {
  loading: false,
  error: "",
   userData: [],
};
const getAllUserByIdSlice = createSlice({
  name: "getAllUserWithId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUserWithId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUserWithId.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    });
    builder.addCase(getAllUserWithId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getAllUserByIdSlice.reducer;