import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPayoutAccount } from "../middleware/getPayOutAccount";

const initialState = {
  loading: false,
  error: "",
  getPayoutAccountData: [],
};
const  getPayoutAccountSlice = createSlice({
  name: "getPayoutAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPayoutAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPayoutAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.payoutSummaryData = action.payload;
    });
    builder.addCase(getPayoutAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getPayoutAccountSlice.reducer;