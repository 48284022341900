import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getLiveEvent = createAsyncThunk("nftData", async (data) => {
    const { eventId } = data;
  try {
    const res = await api.get(`${API_URL}/user/getLiveEvent/${eventId}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getEventsData = createAsyncThunk(
  "nftTableData",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/event/getEventById/${data?.id}`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
