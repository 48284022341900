import React from "react";
import { useEffect, useState } from "react";
import './CollectiblesOwned.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";
const CollectiblesOwned = (collectibles) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles) {
      setLoading(false); 
    }
  }, [collectibles]);

const exportToCSV = () => {
  const csvRows = [];
  
  const headers = ['Picture', 'Collectible Name', 'Description', 'Date', 'Time'];
  csvRows.push(headers.join(','));

  // Add data rows
  collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles?.forEach((row) => {
    const rowData = [
      row?.Collectiblee?.image, 
      row?.Collectiblee?.name,
      row?.Collectiblee?.description,
      row.date.split("T")[0], 
      row.date.split("T")[1].split(".")[0], 
    ];
    csvRows.push(rowData.join(','));
  });

  const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'collectibles_data.csv');
  link.click();
};
  return (

    <>
      {loading && <ScreenLoader />}
    <div className="personal-info-detail-container---ed">
     
      <div className="CollectiblesOwned-to-give-width">
      <Box>
        <Box sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}></Box>

        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
              className="to-apply-table-border-color"
            >
              <TableRow>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929" }}
                  className="even-name-text-style-for"
                >
                  Picture
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929" }}
                  className="even-name-text-style-for"
                >
                  Collectible Name
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid #292929" }}
                  className="even-name-text-style-for"
                >
                Description
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                  className="even-name-text-style-for"
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ borderBottom: "1px solid rgba(41, 41, 41, 1)" }}
                  className="even-name-text-style-for"
                >
                  Time
                </TableCell>
              </TableRow>
            </TableHead>

            <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
              <TableCell colSpan={6}></TableCell>
            </TableRow>

            <TableBody  className="for-to-give-the-padding">
              {collectibles?.collectibles?.userCollectiblesData?.data?.userCollectibles?.map((row, index) => (
                <TableRow key={index} className="main-even-name-text-style-for">
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" }}
                    className="main-even-name-text-style-for-padding-margin-name"
                  >
                    <img
                      src={row?.Collectiblee?.image}
                      alt="Event"
                      style={{ width: "40px" }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" , width:'275px'  }}
                    className="main-even-name-text-style-for-padding-margin-name"
                  >
                    {row?.Collectiblee?.name}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" , width:'275px' }}
                    className="main-even-name-text-style-for-padding-margin-type"
                  >
                    {row?.Collectiblee?.description}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width:'275px'}}
                    className="main-even-name-text-style-for-padding-margin-type"
                  >
                    {row.date.split("T")[0]}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929" , width:'275px' }}
                    className="main-even-name-text-style-for-padding-margin-type"
                  >
                      {row.date.split("T")[1].split(".")[0]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      </div>
   

      <div className="export-button">
        <button onClick={exportToCSV}>Export</button>
      </div>
    </div>
    </>
  );
};

export default CollectiblesOwned;
