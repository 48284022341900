import React, { Act, useState, useEffect } from "react";
import "./Organizer_Detail_Payout.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ScreenLoader from "../loader/screenLoader";
const Organizer_Detail_Payout = (payoutData) => {
  const [Act, SetAct] = useState("A");
  const [payoutDetailData, setPayoutDetailData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleClick = (active) => {
    SetAct(active);
  };

  useEffect(() => {
    setLoading(true);
    try {
      const fetchedData = payoutData?.payoutData?.getuserPayoutData?.data?.data;
      console.log("Payout data is the .....", fetchedData);

      if (fetchedData) {
        setPayoutDetailData(fetchedData);
      }
    } catch (error) {
      console.error("Error occurred while setting events data:", error);
    } finally {
      setLoading(false);
    }
  }, [payoutData]);

  const generateCSVData = () => {
    const headers = [
      "PFP",
      "Name",
      "Email",
      "Event Name",
      "Amount",
      "Withdrawal Method",
      "Date",
    ];

    const rows = payoutDetailData.map((row) => [
      row?.organizerProfile?.profilePicture || "----",
      row?.organizerProfile?.name || "----",
      row?.organizerProfile?.email || "----",
      row?.event?.name || "----",
      row?.amountWithdrawn || "----",
      row?.types || "----",
      row?.createdAt.split("T")[0] || "----",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "payout_data.csv";
    link.click();
  };
  const generateCSVData1 = () => {
    const headers = [
      "Organiser Name",
      "Bank/Wallet Name",
      "Acc No/Wallet Address",
      "Amount",
      "Chain/City",
    ];

    const rows = payoutDetailData.map((row) => [
      row?.organizerProfile?.name || "----",
      row?.bankName || row?.walletName || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.amountWithdrawn || "----",
      row?.chain || row?.city || "----",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV1 = () => {
    const csvData = generateCSVData1();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "payout_account.csv";
    link.click();
  };
  const generateCSVData2 = () => {
    const headers = [
      "Name",
      "Amount",
      "Withdrawal Method",
      "Account Name",
      "Acc No/ Wallet Address",
      "City/Chain",
      "Date",
    ];

    const rows = payoutDetailData.map((row) => [
      row?.organizerProfile?.name || "----",
      row?.amountWithdrawn || "----",
      row?.types || "----",
      row?.accountTitle || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.chain || row?.city || "----",
      row?.createdAt.split("T")[0] || "----",
      row?.Date || "----",
    ]);
    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV2 = () => {
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "payout_data_history.csv";
    link.click();
  };

  return (
    <>
    {loading && <ScreenLoader />}
    <div>
      <div className="Organizer_Detail_Payout_Main">
        <p className="Organizer_Detail_Payout_Main_Ptag">Payout</p>
        <div className="Organizer_Detail_Payout_Main_Buttons__">
          <button
            className="Organizer_Detail_Payout_Main_Buttons__btn1"
            style={{
              background:
                Act === "A"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",
              color: Act === "A" ? "#00A849" : "#FFFFFF",
              border:
                Act === "A"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            onClick={() => handleClick("A")}
          >
            Get Paid Requests
          </button>
          <button
            className="Organizer_Detail_Payout_Main_Buttons__btn2"
            style={{
              background:
                Act === "B"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: Act === "B" ? "#00A849" : "#FFFFFF",
              border:
                Act === "A"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            onClick={() => handleClick("B")}
          >
            Payout Accounts
          </button>
          <button
            className="Organizer_Detail_Payout_Main_Buttons__btn2"
            style={{
              background:
                Act === "C"
                  ? "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(167deg, #13FF7A 0.2%, #002B12 50.2%, #13FF7A 100.2%)border-box "
                  : "linear-gradient(360deg, #0F0F0F 72%, #1A1A1A 100%)padding-box, linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)border-box",

              color: Act === "C" ? "#00A849" : "#FFFFFF",
              border:
                Act === "A"
                  ? "1px solid  transparent"
                  : "1px solid  transparent",
            }}
            onClick={() => handleClick("C")}
          >
            Payout History
          </button>
        </div>

        {Act === "A" && (
          <div>
            {loading && <ScreenLoader />}
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table sx={{ minWidth: 950 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      PFP
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Email
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Event Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Amount
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Withdrawal Method
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{
                        height: "16px",
                        backgroundColor: "transparent",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    ></TableCell>
                  </TableRow>
                  {payoutDetailData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="rganizer_detail_Follower_Main___Body_Top"
                    >
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "1000px",
                          }}
                          src={row?.organizerProfile?.profilePicture}
                          alt=".."
                        />
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.organizerProfile?.name
                          ? row?.organizerProfile?.name
                          : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.organizerProfile?.email
                          ? row?.organizerProfile?.email
                          : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.event?.name ? row?.event?.name : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.amountWithdrawn ? row?.amountWithdrawn : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.types}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.createdAt.split("T")[0]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <button
              onClick={downloadCSV}
              className="Organizer_detail_Follower__Btn"
            >
              Export
            </button>
          </div>
        )}

        {Act === "B" && (
          <div>
            {loading && <ScreenLoader />}
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Organiser Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Bank/Wallet Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Acc No/Wallet Address
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Amount
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Chain/City
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{
                        height: "16px",
                        backgroundColor: "transparent",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    ></TableCell>
                  </TableRow>
                  {payoutDetailData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="rganizer_detail_Follower_Main___Body_Top"
                    >
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.organizerProfile?.name
                          ? row?.organizerProfile?.name
                          : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.bankName || row.walletName}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.IBAN || row?.walletAddress}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.amountWithdrawn ? row?.amountWithdrawn : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.chain || row?.city || "----"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <button
              onClick={downloadCSV1}
              className="Organizer_detail_Follower__Btn"
            >
              Export
            </button>
          </div>
        )}

        {Act === "C" && (
          <div>
            {loading && <ScreenLoader />}
            <TableContainer
              component={Paper}
              className="Organizer_detail_Follower_Main"
            >
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Amount
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Withdrawal Method
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Account Name
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Acc No/ Wallet Address
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      City/Chain
                    </TableCell>
                    <TableCell className="Organizer_detail_Follower_Main___Row">
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{
                        height: "16px",
                        backgroundColor: "transparent",
                        borderBottom: "none",
                        padding: "0px",
                      }}
                    ></TableCell>
                  </TableRow>

                  {payoutDetailData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="rganizer_detail_Follower_Main___Body_Top"
                    >
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.organizerProfile?.name
                          ? row?.organizerProfile?.name
                          : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body">
                        {row?.amountWithdrawn ? row?.amountWithdrawn : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.types}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.accountTitle ? row?.accountTitle : "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.IBAN || row?.walletAddress}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.chain || row?.city || "----"}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row?.createdAt.split("T")[0]}
                      </TableCell>
                      <TableCell className="Organizer_detail_Follower_Main___Body1">
                        {row.Date}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <button
              onClick={downloadCSV2}
              className="Organizer_detail_Follower__Btn"
            >
              Export
            </button>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Organizer_Detail_Payout;
