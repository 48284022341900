import React from "react";
import { useEffect, useState } from "react";
import "./New_Updated_Payout_GetPaid.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPaidRequest } from "../../services/redux/middleware/getPaidRequest";
import ScreenLoader from "../loader/screenLoader";
const New_Updated_Payout_GetPaid = (payoutId) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleNavigate = (payoutId) => {
    navigate(`/GetPaidRequest_After_Qa/${payoutId}`);
  };
  const navigate2 = useNavigate();
  const goBack = () => {
    navigate2(-1);
  };

  const dispatch = useDispatch();
  const paidRequestData = useSelector(
    (state) => state?.getPaidRequest?.getPaidRequestData?.data?.data
  );
  console.log("paid request  data is...", paidRequestData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPaidRequest()).finally(() => setLoading(false));
  }, [dispatch]);
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="new_updated_payout_to_give_margen_________">
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            overflowX: "auto",
          }}
          className="Organizer_detail_Follower_Main_____
"
        >
          <Table
            sx={{ minWidth: 1000, maxWidth: "100%" }}
            aria-label="simple table"
          >
            <TableHead sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
              <TableRow>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Profile
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Name
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Email
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  No of Events
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Amount
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Date
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Row">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={8}
                  style={{
                    height: "16px",
                    backgroundColor: "#030303",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                ></TableCell>
              </TableRow>
              {paidRequestData?.map((row, index) => (
                <TableRow
                  key={index}
                  className="rganizer_detail_Follower_Main___Body_Top"
                >
                  <TableCell className="Organizer_detail_Follower_Main___Body">
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "1000px",
                      }}
                      src={row?.profile ? row?.profile : "profile"}
                    />
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Body">
                    {row?.name ? row?.name : "----"}
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Body1">
                    {row?.email ? row?.email : "----"}
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Body1">
                    {row?.noOfEvents ? row?.noOfEvents : "----"}
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Body1">
                    {row?.amountWithdrawn ? row?.amountWithdrawn : "----"}
                  </TableCell>
                  <TableCell className="Organizer_detail_Follower_Main___Body1">
                    {row?.date ? row?.date : "----"}
                  </TableCell>

                  <TableCell className="Organizer_detail_Follower_Main___Body">
                    <img
                      src="/Images/New_updated_get_piad/teer.svg"
                      alt=".."
                      onClick={() => handleNavigate(row?.eventId)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default New_Updated_Payout_GetPaid;
