import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getPaidRequest = createAsyncThunk("getPaidRequest", async () => {
  try {
    const res = await api.get(`${API_URL}/help/getUserPayoutSummary`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

