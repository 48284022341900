import React from 'react';
import './LikedEvents.css'

const LikedEvents = () => {
  return (
    <div className="personal-info-detail-container----liked">
        {/* <div className='Simple-User-Details-heading-main'>
        <h2 className='Simple-User-Details-heading'>Simple User Details</h2>
      <div className="top-buttons">
        <button>Personal Information</button>
        <button>Event Tickets</button>
        <button>NFT Rewards</button>
        <button>Collectibles Owned</button>
        <button>Photo Roll</button>
        <button>Liked Events</button>
        <button>Following</button>
      </div>
      </div> */}
      <div className='photoroll-photos-container-eventliked'>
       <div className='eventliked-main-images'>
        <div></div>
        <div className='NAITRAM-Launch-Party'>
            <h2>NAITRAM Launch Party 2024</h2>
            <p>Saturday, 5th March 2024 - 5 PM - 12 AM</p>
        </div>
       </div>
       <div className='eventliked-main-images-2'>
        <div></div>
        <div className='NAITRAM-Launch-Party'>
            <h2>NAITRAM Launch Party 2024</h2>
            <p>Saturday, 5th March 2024 - 5 PM - 12 AM</p>
        </div>
       </div>
       <div className='eventliked-main-images-3'>
        <div></div>
        <div className='NAITRAM-Launch-Party'>
            <h2>NAITRAM Launch Party 2024</h2>
            <p>Saturday, 5th March 2024 - 5 PM - 12 AM</p>
        </div>
       </div>
       <div className='eventliked-main-images-4'>
        <div></div>
        <div className='NAITRAM-Launch-Party'>
            <h2>NAITRAM Launch Party 2024</h2>
            <p>Saturday, 5th March 2024 - 5 PM - 12 AM</p>
        </div>
       </div>
       <div className='eventliked-main-images-5'>
        <div></div>
        <div className='NAITRAM-Launch-Party'>
            <h2>NAITRAM Launch Party 2024</h2>
            <p>Saturday, 5th March 2024 - 5 PM - 12 AM</p>
        </div>
       </div>
       <div className='eventliked-main-images-6'>
        <div></div>
        <div className='NAITRAM-Launch-Party'>
            <h2>NAITRAM Launch Party 2024</h2>
            <p>Saturday, 5th March 2024 - 5 PM - 12 AM</p>
        </div>
       </div>
      </div>
    

      <div className="export-button">
        <button>Export</button>
      </div>
    </div>
  );
};

export default LikedEvents;
