import React, { useEffect, useState } from "react";
// import './CollectiblesOwned.css'
import "./Attendees_Hosted_Events.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getEventsAttendees } from "../../services/redux/middleware/getEvents";
import ScreenLoader from "../loader/screenLoader";

const Attendeed_Hosted_Events = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const [attendenceData, setattendenceData] = useState([]);
  const getattendenceData = useSelector((state) => state?.getEventsAttendees);

  useEffect(() => {
    setLoading(true);
    dispatch(getEventsAttendees({ eventId: eventId })).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    if (getattendenceData) {
      setattendenceData(getattendenceData?.getEventsAttendeesData?.data?.data);
    }
  }, [getattendenceData]);

  const handleExportCSV = () => {
    const csvData = [
      ["Profile", "Attendee Name", "Email", "Ticket Type", "Date"],
      ...attendenceData.map((row) => [
        row.profile || "N/A",
        row.attendeeName || "N/A",
        row.email || "N/A",
        row.ticketType || "N/A",
        row.date || "N/A",
      ]),
    ];

    const csvContent = csvData.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "attendees_hosted_events.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      <div className="personal-info-detail-container____________chnge">
        <div
          style={{ paddingLeft: "0px" }}
          className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image"
        >
          <img src="/Payout_Images/graphlogo.svg" onClick={goBack} />
          <h2>Attendees</h2>
        </div>
        <div className="CollectiblesOwned-to-give-width-attendeed">
          <Box>
            <Box
              sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                  className="to-apply-table-border-color"
                >
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "105px" }}
                      className="even-name-text-style-for"
                    >
                      Profile
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Attendee Name
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "220px" }}
                      className="even-name-text-style-for"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Ticket Type
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "220px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableRow
                  sx={{
                    height: "16px",
                    backgroundColor: "#030303",
                    width: "220px",
                  }}
                >
                  <TableCell colSpan={6}></TableCell>
                </TableRow>

                <TableBody className="for-to-give-the-padding">
                  {attendenceData?.map((row, index) => (
                    <TableRow
                      key={index}
                      className="main-even-name-text-style-for"
                    >
                      <TableCell
                        sx={{ borderBottom: "1px solid #292929" }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        <img
                          src={row?.profile}
                          alt="Event"
                          style={{ width: "40px" }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "105px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        {row?.attendeeName}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.ticketType}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "220px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.date}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div className="export-button">
          <button onClick={handleExportCSV} className="explore___button1">
            Export
          </button>
        </div>
      </div>
    </>
  );
};

export default Attendeed_Hosted_Events;
