import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserCollectibles } from "../middleware/getUserCollectibles";
import { getLikedEvents } from "../middleware/getLikedEvents";
import { getLikedEventsOrganizer } from "../middleware/getLikedEventsOrganizer";

const initialState = {
  loading: false,
  error: "",
  getLikedEventsData: [],
};
const getLikedEventsOrganizeSlice = createSlice({
  name: "getLikedEventsOrganizer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLikedEventsOrganizer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLikedEventsOrganizer.fulfilled, (state, action) => {
      state.loading = false;
      state.getLikedEventsData = action.payload;
    });
    builder.addCase(getLikedEventsOrganizer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getLikedEventsOrganizeSlice.reducer;