import React from "react";
import { useState, useEffect } from "react";
import "./PhotoRoll.css";
import ScreenLoader from "../../Components/loader/screenLoader";
const PhotoRoll = (photoData) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(
    "photoData",
    photoData?.photoData?.getChatPicturesData?.data?.data
  );
  useEffect(() => {
    setLoading(true);

    if (photoData?.photoData?.getChatPicturesData?.data?.data) {
      setPhotos(photoData.photoData.getChatPicturesData.data.data);
      setLoading(false);
    }
  }, [photoData]);

  console.log("photos state:", photos);

  const convertToCSV = (photos) => {
    const header = ["Index", "Picture URL"];
    const rows = photos.map((photo, index) => [index + 1, photo?.picture]);

    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(photos);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "photos.csv";
    link.click();

    URL.revokeObjectURL(url);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="personal-info-detail-container---roll">
        <div className="photoroll-photos-container">
          {photos.map((photo, index) => (
            <img
              style={{ height: "140px", width: "140px", borderRadius: "8px" }}
              key={index}
              src={photo?.picture}
              alt={`Photo ${index + 1}`}
            />
          ))}
        </div>

        <div className="export-button">
          <button onClick={downloadCSV}>Export</button>
        </div>
      </div>
    </>
  );
};

export default PhotoRoll;
