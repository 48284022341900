import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getKycRequest } from "../middleware/getKycRequest";

const initialState = {
  loading: false,
  error: "",
  kycRequestData : [],
};
const getKycRequestSlice = createSlice({
  name: "getKycRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getKycRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKycRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.kycRequestData = action.payload;
    });
    builder.addCase(getKycRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getKycRequestSlice.reducer;