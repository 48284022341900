import React from "react";
import { useEffect, useState } from "react";
import "./User_Scanner_Scanner.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizer } from "../../services/redux/middleware/getOrganizor";
import ScreenLoader from "../loader/screenLoader";
const User_Scanner_Scanner = () => {
  const navigate = useNavigate();
  const handleNavigate = (organizerId) => {
    navigate(`/personaluserorganizer/${organizerId}`);
  };
  const navigate2 = useNavigate();
  const [loading, setLoading] = useState(true);
  const goBack = () => {
    navigate2(-1);
  };
  const dispatch = useDispatch();
  const organizerData = useSelector((state) => state?.getOrganizer);
  console.log("organozationdata is...", organizerData);

  useEffect(() => {
    setLoading(true); 
    dispatch(getOrganizer())
      .finally(() => setLoading(false)); 
  }, [dispatch]);
  const downloadCSV = () => {
    const data = organizerData?.userData?.data;
    if (data && data.length > 0) {
      const header = [
        "PFP", 
        "Name", 
        "Email", 
        "Role", 
        "Date Joined", 
        "Hosted Events"
      ];
      const rows = data.map((row) => [
        row?.pfp || "",
        row?.name || "----",
        row?.email || "----",
        row?.role || "---",
        row?.dateJoined.split("T")[0] || "----",
        row?.hostedEvents || "----",
      ]);

      const csvContent = [
        header.join(","),
        ...rows.map(row => row.join(","))
      ].join("\n");

     
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "organizer_data.csv";
      link.click();
    }
  };
  return (
    <div>
       {loading && <ScreenLoader />}
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.06)",
          overflowX: "auto",
        }}
        className="Organizer_detail_Follower_Main_____
"
      >
        <Table
          sx={{ minWidth: 1000, maxWidth: "100%" }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}>
            <TableRow>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                PFP
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Email
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Role
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Date Joined
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Hosted Events
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                View
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                colSpan={8}
                style={{
                  height: "16px",
                  backgroundColor: "#030303",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>
            {organizerData?.userData?.data?.map((row, index) => (
              <TableRow
                key={index}
                className="rganizer_detail_Follower_Main___Body_Top"
              >
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  <img
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "1000px",
                      
                    }}
                    src={row?.pfp}
                    alt=''
                  />
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  {row?.name ?row?.name:"----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.email ?row?.email:'----'}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.role ? row?.role:"---"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.dateJoined.split("T")[0] ? row?.dateJoined.split("T")[0]:"----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.hostedEvents ? row?.hostedEvents:"----"}
                </TableCell>

                <TableCell className="Organizer_detail_Follower_Main___Body">
                  <img
                    src="/Images/scannereyemain/eyescanner.svg"
                    alt=".."
                    onClick={()=>handleNavigate(row?.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="export-button">
        <button onClick={downloadCSV}>Export</button>
      </div>
    </div>
  );
};

export default User_Scanner_Scanner;
