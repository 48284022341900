import React from "react";
import { useEffect ,useState} from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getKycById } from "../../services/redux/middleware/getKycById";
import "./RequestS_Details_Owners.css";
import { approveStatus } from "../../services/redux/middleware/approveStatus";
import ScreenLoader from "../loader/screenLoader";
const RequestS_Details_Owners = () => {
  const { kycId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const kycDataById = useSelector(
    (state) => state?.getKycById?.kycRequestData?.data?.data
  );
  console.log("kyc approval data by id is...", kycDataById);

  useEffect(() => {
    setLoading(true);
    dispatch(getKycById({ id: kycId })).finally(() => setLoading(false));
  }, [dispatch]);
  useEffect(() => {
    if (kycDataById) {
      console.log("User ID from KYC data: ", kycDataById.userId);
    }
  }, [kycDataById]);

  const handleApprove = async () => {
    if (!kycDataById?.userId) {
      toast.error("User ID is missing");
      return;
    }

    try {
      const data = { userId: kycDataById.userId, approved: 1 };
      console.log("Approving status with data:", data);

      const result = await dispatch(approveStatus(data));
      console.log("Dispatch result:", result);

      if (result.type === "approveStatus/fulfilled") {
        if (result.payload?.status === 201) {
          console.log("Approval success, showing toast");
          toast.success("Approved successfully");
        } else {
          toast.success("Approved successfully");
        }
      } else {
        toast.success("Approved successfully");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };
  return (
    <>
    {loading && <ScreenLoader />}
    <div>
      <TableContainer
        component={Paper}
        className="Organizer_detail_Follower_Main"
      >
        <Table sx={{ minWidth: 650, maxWidth: 780 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="Organizer_detail_Follower_Main___Row ">
                Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Relationship
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Percentage
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                colSpan={3}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>

            <TableRow className="Organizer_detail_Follower_Main___Body_Top">
              <TableCell className="Organizer_detail_Follower_Main___Body">
                {`${kycDataById?.Owner?.FirstName || ""} ${
                  kycDataById?.Owner?.LastName || ""
                }`}
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Body1">
                {kycDataById?.Owner?.relationship || ""}
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Body1">
                {kycDataById?.Owner?.percentage || ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className="RequestS_Details_Representative_divlast">
        <button
          onClick={handleApprove}
          className="RequestS_Details_Representative_divlastBtn1"
        >
          Aprove
        </button>
        <button className="RequestS_Details_Representative_divlastBtn2">
          Deny
        </button>
      </div>
    </div>
    </>
  );
};

export default RequestS_Details_Owners;
