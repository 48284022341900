import React, { useEffect, useState } from "react";
import "./Organiser_Detail_HostedEvents_Sales_Analytics.css";
import SalesAnalytics_table1 from "../../Components/SalesAnalytics_table1/SalesAnalytics_table1";
import SalesAnalytics_table2__2 from "../../Components/SalesAnalytics_table2__/SalesAnalytics_table2__2";
import SalesAnalytics_table_3 from "../../Components/SalesAnalytics_table_3/SalesAnalytics_table_3";
import SalesAnalyst_Graph from "../../Components/SalesAnalyst_Graph/SalesAnalyst_Graph";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLiveEvent } from "../../services/redux/middleware/getLiveEvent";
import ScreenLoader from "../../Components/loader/screenLoader";
const Organiser_Detail_HostedEvents_Sales_Analytics = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saleData, setSalesData] = useState();
  const [salesAnalyticsData, setsalesAnalyticsData] = useState([]);
  const getsalesAnalyticsData = useSelector((state) => state?.getLiveEvent);
  //
  useEffect(() => {
    setLoading(true);
    dispatch(getLiveEvent({ eventId: eventId })).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    if (getsalesAnalyticsData) {
      setsalesAnalyticsData(getsalesAnalyticsData);
    }
  }, [getsalesAnalyticsData]);
  console.log("getsalesAnalyticsData", salesAnalyticsData);
  useEffect(() => {
    if (salesAnalyticsData) {
      setSalesData(salesAnalyticsData);
    }
  });
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main">
        <div className="Organiser_Detail_HostedEvents_Sales_Analytics_Main_heading_and_image">
          <img src="/Payout_Images/graphlogo.svg" onClick={goBack} alt="" />
          <h2>Sales Analytics</h2>
        </div>
        <SalesAnalyst_Graph />
        <SalesAnalytics_table1 salesData={saleData} />
        <SalesAnalytics_table2__2 salesData={saleData} />
        <SalesAnalytics_table_3 salesData={saleData} />

        <button className="Organizer_detail_Follower__Btn">Export</button>
      </div>
    </>
  );
};

export default Organiser_Detail_HostedEvents_Sales_Analytics;
