import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getPayoutSummaryDetail= createAsyncThunk("getPayoutSummaryDetail", async (data) => {
  const { eventId } = data; 
  try {
    const res = await api.get(`${API_URL}/user/payoutFunds/${eventId}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

