import React from "react";
import { useEffect, useState } from "react";
import "./Kyc_Updated_Request_Approval.css";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getKycRequest } from "../../services/redux/middleware/getKycRequest";
import ScreenLoader from "../loader/screenLoader";
const Kyc_Updated_Request_Approval = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleNavigate = (kycId) => {
    navigate(`/KycIndivialDetailTable/${kycId}`);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const kycRequestData = useSelector(
    (state) => state?.getKycRequest?.kycRequestData?.data?.data
  );
  console.log("kyc request data  is...", kycRequestData);

  useEffect(() => {
    setLoading(true);
    dispatch(getKycRequest()).finally(() => setLoading(false));
  }, [dispatch]);

  const handleExport = () => {
    const csvHeader = ["Name", "Email", "DoB", "Role", "Website", "Date"];
    const csvRows = kycRequestData?.map((row) => [
      `${row?.Individual?.[0]?.FirstName || ""} ${
        row?.Individual?.[0]?.LastName || ""
      }`.trim(),
      row?.Individual?.[0]?.Email || "---",
      row?.Individual?.[0]?.DOB || "---",
      row?.userType || "----",
      row?.companyWebsite || "----",
      row?.createdAt.split("T")[0] || "---",
    ]);

    const csvContent = [
      csvHeader.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "kyc_request_data.csv";
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <>
      {loading && <ScreenLoader />}
      <div className="">
        <Box>
          <Box sx={{ backgroundColor: "#000", mb: "16px" }}></Box>
          <TableContainer
            className="Organizer_detail_Follower_Main_____for_bg"
            component={Paper}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                className="to-apply-table-border-color"
              >
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                    className="even-name-text-style-for"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "200px" }}
                    className="even-name-text-style-for"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #292929", width: "131px" }}
                    className="even-name-text-style-for"
                  >
                    DoB
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "175px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "175px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Website
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "130px",
                    }}
                    className="even-name-text-style-for"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(41, 41, 41, 1)",
                      width: "104px",
                    }}
                    className="even-name-text-style-for"
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                <TableCell colSpan={8}></TableCell>
              </TableRow>
              <TableBody className="for-to-give-the-padding">
                {kycRequestData?.map((row, index) => (
                  <TableRow
                    key={index}
                    className="main-even-name-text-style-for"
                  >
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "188px" }}
                      className="main-even-name-text-style-for-padding-margin-name"
                    >
                      {`${row?.Individual?.[0]?.FirstName || ""} ${
                        row?.Individual?.[0]?.LastName || ""
                      }`.trim() || "---"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "200px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.Individual?.[0]?.Email || "---"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "130px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.Individual?.[0]?.DOB || "---"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "175px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.userType ? row?.userType : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "175px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.companyWebsite ? row?.companyWebsite : "----"}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "130px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      {row?.createdAt.split("T")[0]}
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "104px" }}
                      className="main-even-name-text-style-for-padding-margin-type"
                    >
                      <img
                        onClick={() => {
                          console.log("id is", row?.id);
                          handleNavigate(row?.id);
                        }}
                        src="/Images/Kyc_Updated_ver/kyceye.svg"
                        alt="Eye View"
                        style={{ width: "24px" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <div className="export-button">
          <button onClick={handleExport}>Export</button>
        </div>
      </div>
    </>
  );
};
export default Kyc_Updated_Request_Approval;
