import React, { useState, useEffect } from "react";
import "./EventTicket.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenLoader from "../../Components/loader/screenLoader";

const EventTicket = (event) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(true);
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (event?.event?.getEventsDetailsData?.data?.events) {
      setEventsData(event?.event?.getEventsDetailsData?.data?.events);
      setLoading(false);
    }
  }, [event]);

  console.log("event detail", eventsData);

  const exportToCSV = () => {
    const csvHeaders = [
      "Event Name",
      "Ticket Type",
      "Price",
      "Date",
      "Attended",
      "Check In Time",
    ];

    const csvRows = eventsData.map((row) => [
      row?.eventName || "----",
      row?.tickets[0]?.type || "----",
      row?.tickets[0]?.price || "----",
      row?.startTime.split("T")[0] || "----",
      row.attended ? "Yes" : "No",
      row.startTime.split("T")[1]?.split(".")[0] || "----",
    ]);

    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Event_Ticket_History.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {loading && <ScreenLoader />}

      <div className="personal-info-detail-container-even-ticket">
        <div className="EventTicket-to-give-max-width">
          <Box>
            <Box
              sx={{ height: "50px", backgroundColor: "#000", mb: "16px" }}
            ></Box>

            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.06)",
                    width: "275px",
                    borderBottom: "1px solid #292929 ",
                  }}
                  className="to-apply-table-border-color"
                >
                  <TableRow className="tble-row-btm">
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "200px" }}
                      className="even-name-text-style-for"
                    >
                      Event Name
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "1px solid #292929", width: "200px" }}
                      className="even-name-text-style-for"
                    >
                      Ticket Type
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "200px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Price
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "170px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "170px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Attended
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(41, 41, 41, 1)",
                        width: "195px",
                      }}
                      className="even-name-text-style-for"
                    >
                      Check In Time
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Spacer row for gap */}
                <TableRow sx={{ height: "16px", backgroundColor: "#030303" }}>
                  <TableCell colSpan={6}></TableCell>
                </TableRow>

                <TableBody>
                  {eventsData.map((row, index) => (
                    <TableRow
                      key={index}
                      className="main-even-name-text-style-for"
                    >
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "275px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-name"
                      >
                        {row?.eventName}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "200px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.tickets[0]?.type}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "200px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row?.tickets[0]?.price}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "200px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row.startTime.split("T")[0]}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "170px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type-false-true"
                      >
                        {row.attended ? (
                          <Check sx={{ color: "rgba(0, 208, 89, 1)" }} />
                        ) : (
                          <Close sx={{ color: "rgba(255, 23, 23, 1)" }} />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #292929",
                          width: "195px",
                        }}
                        className="main-even-name-text-style-for-padding-margin-type"
                      >
                        {row.startTime.split("T")[1].split(".")[0]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>

        <div className="export-button">
          <button onClick={exportToCSV}>Export</button>
        </div>
      </div>
    </>
  );
};

export default EventTicket;
