import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const approveStatus = createAsyncThunk("approveStatus", async (data, { rejectWithValue }) => {
  try {
    console.log("Approve status request sent");
    const res = await api.post(`${API_URL}/helpcenter/updateKYCStatus`, data);
    return {
      status: res?.status,
      data: res?.data?.data,
      token: res?.data?.token,
    };
  } catch (error) {
    // Return error as rejected value for better error handling
    return rejectWithValue({
      errorMessage: error?.response?.data?.error || "An error occurred",
      status: error?.response?.status || 500,
    });
  }
});
