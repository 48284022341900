import React from "react";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./After___QA__PayoutHistory2.css";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutAccount } from "../../services/redux/middleware/getPayOutAccount";
import ScreenLoader from "../loader/screenLoader";
const After___QA__PayoutHistory2 = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const paidAccountData = useSelector(
    (state) => state?.getPayoutAccount?.payoutSummaryData?.data?.data
  );
  console.log("paid account  data is...", paidAccountData);

  useEffect(() => {
    setLoading(true);
    dispatch(getPayoutAccount())
    .finally(() => setLoading(false));
  }, [dispatch]);
  const exportToCSV = () => {
    const csvHeaders = [
      "Organiser Name",
      "Bank/Wallet Name",
      "Acc No/Wallet Address",
      "Amount",
      "Chain/City",
    ];
    const csvRows = paidAccountData?.map((row) => [
      row?.organizerProfile?.name || "----",
      row?.bankName || "----",
      row?.IBAN || row?.walletAddress || "----",
      row?.amountWithdrawn || "----",
      row?.chain || row?.city || "----",
    ]);

    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Payout_Account.csv";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
    {loading && <ScreenLoader />}
    <div>
      <TableContainer
        component={Paper}
        className="Organizer_detail_Follower_Main"
      >
        <Table sx={{ minWidth: 750, maxWidth: 1070 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Organiser Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Bank/Wallet Name
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Acc No/Wallet Address
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Amount
              </TableCell>
              <TableCell className="Organizer_detail_Follower_Main___Row">
                Chain/City
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                colSpan={5}
                style={{
                  height: "16px",
                  backgroundColor: "transparent",
                  borderBottom: "none",
                  padding: "0px",
                }}
              ></TableCell>
            </TableRow>

            {paidAccountData?.map((row, index) => (
              <TableRow
                key={index}
                className="rganizer_detail_Follower_Main___Body_Top"
              >
                <TableCell className="Organizer_detail_Follower_Main___Body">
                  {row?.organizerProfile?.name
                    ? row?.organizerProfile?.name
                    : "----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.bankName || row?.bankName || "----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.IBAN || row?.walletAddress || "----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.amountWithdrawn ? row?.amountWithdrawn : "----"}
                </TableCell>
                <TableCell className="Organizer_detail_Follower_Main___Body1">
                  {row?.chain || row?.city || "----"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <button onClick={exportToCSV} className="User__detail_Scanner___Btn">
        Export
      </button>
    </div>
    </>
  );
};

export default After___QA__PayoutHistory2;
